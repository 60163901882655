import React from "react";
import { useEffect } from "react";
import { AiOutlineRight } from "react-icons/ai";
import "./style/SideMenuPages.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { RightSlider } from "./Home";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Pagination } from "react-bootstrap";
import { Helmet } from "react-helmet";

const CurrentOpenings = [
  "Advertisement for appointment of FLC 22.11.2022",
  "Biodata Form For Apprentice",
  "Medical Format For Apprentice",
  "Advertisement for appointment of FLC 22.11.2022",
  "Biodata Form For Apprentice",
  "Medical Format For Apprentice",
  "Advertisement for appointment of FLC 22.11.2022",
  "Biodata Form For Apprentice",
  "Medical Format For Apprentice",
];

export const Eligibility = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <div class="products7 text-start py-4" id="eligibility">
      {/* <p class='fs-2 fw-semibold' data-aos="fade-up">Eligibility</p> */}
      <div
        class=""
        style={{ overflowX: "auto", height: "100%", overflowY: "hidden" }}
      >
        <table style={{ width: "100%" }} data-aos="fade-up">
          <tr>
            <th style={{ background: "#f0f0f0" }}>
              <p class="px-3 m-0">OFFICE</p>
            </th>
            <th style={{ background: "#f0f0f0" }}>
              <p class="px-3 m-0">SOL</p>
            </th>
            <th style={{ background: "#f0f0f0" }}>
              <p class="px-3 m-0">REGION</p>
            </th>
            <th style={{ background: "#f0f0f0" }}>
              <p class="px-3 m-0">DISTRICT</p>
            </th>
            <th style={{ background: "#f0f0f0" }}>
              <p class="px-3 m-0">BRANCH </p>
            </th>
            <th style={{ background: "#f0f0f0" }}>
              <p class="px-3 m-0">MICR CODE</p>
            </th>
            <th style={{ background: "#f0f0f0" }}>
              <p class="px-3 m-0">MICR ALPHA</p>
            </th>
            <th style={{ background: "#f0f0f0" }}>
              <p class="px-3 m-0">IFSC</p>
            </th>
            <th style={{ background: "#f0f0f0" }}>
              <p class="px-3 m-0">CONTACT</p>
            </th>
            <th style={{ background: "#f0f0f0" }}>
              <p class="px-3 m-0">DIRECTIONS</p>
            </th>
          </tr>
          <tr>
            <td>
              <p class="px-3 m-0">THAURI</p>
            </td>
            <td>
              <p class="px-3 m-0">5938</p>
            </td>
            <td>
              <p class="px-3 m-0">AMETHI</p>
            </td>
            <td>
              <p class="px-3 m-0">AMETHI</p>
            </td>
            <td>
              <p class="px-3 m-0">THAURI</p>
            </td>
            <td>
              <p class="px-3 m-0">227454158</p>
            </td>
            <td>
              <p class="px-3 m-0">BNJ</p>
            </td>
            <td>
              <p class="px-3 m-0">BARAOBPGBX</p>
            </td>
            <td>
              <p class="px-3 m-0">9874563210</p>
            </td>
            <td>
              <p class="px-3 m-0">
                <a class="text-black" href="">
                  Click Know
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="px-3 m-0">THAURI</p>
            </td>
            <td>
              <p class="px-3 m-0">5938</p>
            </td>
            <td>
              <p class="px-3 m-0">AMETHI</p>
            </td>
            <td>
              <p class="px-3 m-0">AMETHI</p>
            </td>
            <td>
              <p class="px-3 m-0">THAURI</p>
            </td>
            <td>
              <p class="px-3 m-0">227454158</p>
            </td>
            <td>
              <p class="px-3 m-0">BNJ</p>
            </td>
            <td>
              <p class="px-3 m-0">BARAOBPGBX</p>
            </td>
            <td>
              <p class="px-3 m-0">9874563210</p>
            </td>
            <td>
              <p class="px-3 m-0">
                <a class="text-black" href="">
                  Click Know
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="px-3 m-0">THAURI</p>
            </td>
            <td>
              <p class="px-3 m-0">5938</p>
            </td>
            <td>
              <p class="px-3 m-0">AMETHI</p>
            </td>
            <td>
              <p class="px-3 m-0">AMETHI</p>
            </td>
            <td>
              <p class="px-3 m-0">THAURI</p>
            </td>
            <td>
              <p class="px-3 m-0">227454158</p>
            </td>
            <td>
              <p class="px-3 m-0">BNJ</p>
            </td>
            <td>
              <p class="px-3 m-0">BARAOBPGBX</p>
            </td>
            <td>
              <p class="px-3 m-0">9874563210</p>
            </td>
            <td>
              <p class="px-3 m-0">
                <a class="text-black" href="">
                  Click Know
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="px-3 m-0">THAURI</p>
            </td>
            <td>
              <p class="px-3 m-0">5938</p>
            </td>
            <td>
              <p class="px-3 m-0">AMETHI</p>
            </td>
            <td>
              <p class="px-3 m-0">AMETHI</p>
            </td>
            <td>
              <p class="px-3 m-0">THAURI</p>
            </td>
            <td>
              <p class="px-3 m-0">227454158</p>
            </td>
            <td>
              <p class="px-3 m-0">BNJ</p>
            </td>
            <td>
              <p class="px-3 m-0">BARAOBPGBX</p>
            </td>
            <td>
              <p class="px-3 m-0">9874563210</p>
            </td>
            <td>
              <p class="px-3 m-0">
                <a class="text-black" href="">
                  Click Know
                </a>
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};
const BranchLocater = ({ lang }) => {
  const navigate = useNavigate();
  const [table, setTable] = useState();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const branchLocaterData = () => {
    axios
      .post("https://tgbdemo.techelecon.net/api/branch_detail.php", {
        lang: lang,
      })
      .then((response) => {
        setTable(response.data.responseResult);
      })
      .catch((error) => console.log("error", error));
  };
  const tBodyData = table?.tBody;
  const tHeadData = table?.tHead;

  const tBodyyyy = tBodyData?.filter((el) => {
    if (search == "") {
      return el;
    } else if (el.office.toLowerCase().includes(search.toLowerCase())) {
      return el;
    } else if (el.sol.toLowerCase().includes(search.toLowerCase())) {
      return el;
    } else if (el.region.toLowerCase().includes(search.toLowerCase())) {
      return el;
    } else if (el.district.toLowerCase().includes(search.toLowerCase())) {
      return el;
    } else if (el.branch.toLowerCase().includes(search.toLowerCase())) {
      return el;
    } else if (el.micr_code.toLowerCase().includes(search.toLowerCase())) {
      return el;
    } else if (el.bsr_code.toLowerCase().includes(search.toLowerCase())) {
      return el;
    } else if (el.tan_no.toLowerCase().includes(search.toLowerCase())) {
      return el;
    } else if (el.isfc.toLowerCase().includes(search.toLowerCase())) {
      return el;
    } else if (el.contact_no.toLowerCase().includes(search.toLowerCase())) {
      return el;
    }
  });

  const totalPages = Math.ceil(tBodyyyy?.length / 10);
  const indexOfLastItem = currentPage * 10;
  const indexOfFirstItem = indexOfLastItem - 10;
  const currentItems = tBodyyyy?.slice(indexOfFirstItem, indexOfLastItem);
  //
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const tBody = currentItems?.map((el, i) => {
    return (
      <tr key={i}>
        <td>
          <p class=" m-0">{indexOfFirstItem + i + 1}</p>
        </td>
        <td>
          <p class=" m-0">{el.office}</p>
        </td>
        <td>
          <p class=" m-0">{el.sol}</p>
        </td>
        <td>
          <p class=" m-0">{el.region}</p>
        </td>
        <td>
          <p class=" m-0">{el.district}</p>
        </td>
        <td>
          <p class=" m-0">{el.branch}</p>
        </td>
        <td>
          <p class=" m-0">{el.micr_code}</p>
        </td>
        <td>
          <p class=" m-0">{el.bsr_code}</p>
        </td>
        <td>
          <p class=" m-0">{el.tan_no}</p>
        </td>
        <td>
          <p class=" m-0">{el.isfc}</p>
        </td>
        <td>
          <p class=" m-0">{el.contact_no}</p>
        </td>
        <td>
          <button class="btn btn-success" style={{ height: "40px" }}>
            <a href={`/${el.location}`} target="_blank">
              {" "}
              <p class="text-nowrap">Get Direction</p>{" "}
            </a>
          </button>
        </td>
      </tr>
    );
  });

  useEffect(() => {
    branchLocaterData();
    AOS.init({ duration: 1500 });
  }, [lang]);
  return (
    <div class="slidePage">
       <Helmet>
            <meta charSet="utf-8" />
            <title>Branch Locator</title>
            <meta name="description" content="Branch Locator" />
        </Helmet>
      <div class="p-3 text-start" style={{ background: "#f3f3f3" }}>
        <p class="m-0">
          <span>Branch Locator</span>
        </p>
        <p class="fs-2 fw-semibold">Branch Locator</p>
      </div>
      <div clas="text-start" style={{ padding: "25px" }}>
        <div class="text-start">
          <input
            type="text"
            class="form-control"
            placeholder="Search here "
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div
          class="mt-4"
          style={{ overflowX: "auto", height: "100%", overflowY: "hidden" }}
        >
          <Table striped bordered hover>
            <thead>
              <tr>
                <th
                  style={{ background: "#BB355A" }}
                  class="text-center text-white"
                >
                  <p class=" m-0 text-white">No.</p>
                </th>
                {tHeadData?.map((el) => {
                  return (
                    <th
                      style={{ background: "#BB355A" }}
                      class="text-center text-white"
                    >
                      <p class="m-0 text-nowrap text-white">{el.headName}</p>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>{tBody}</tbody>
          </Table>
          <div>
            <Pagination>
              {Array.from({ length: totalPages }, (_, i) => (
                <Pagination.Item
                  key={i}
                  active={i + 1 === currentPage}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default BranchLocater;
