import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "./style/profile.css";
import swal from "sweetalert";
import { Helmet } from "react-helmet";

export const UserTable = () => {
  const [state, setState] = useState({});
  let id = sessionStorage.getItem("userId");
  const navigate = useNavigate();

  let Access_token = sessionStorage.getItem("Access_token");
  let roleType = sessionStorage.getItem("roleType");



  const userDetails = () => {
    axios
      .post("https://tgbdemo.techelecon.net/api/profile.php", {AccessToken :Access_token })
      .then((res) => {
        if(res.data.responseStatus){ 
          setState(res.data);
        }else{
          navigate('/log-in')
        }
      })
      .catch((err) => {
        swal('You need to provide valid credentials');

      });
  };
  useEffect(() => {
    userDetails();
  }, []);
  const data = state?.userDetails;

  return (
    <div class="products7 text-start py-4" id="eligibility"> 
      <div class="" style={{ overflowX: "scroll", overflowY: "hidden" }}>
        <table style={{ width: "100%" }} data-aos="fade-up">
          {data?.map((el) => {
            return (
              <tr>
                <td>
                  <p class="p-2 m-0">{el.title}</p>
                </td>
                <td>
                  <p class="p-2 m-0">{el.detils}</p>
                </td>
              </tr>
            );
          })}
        </table>
        {
          roleType == "Employee" ?
        <button
          class="btn btn-success p-2 px-3 my-4"
          onClick={() => navigate("/download-form")}
        >
          {" "}
          Download Circulars
        </button> 
        :
        <button
          class="btn btn-success p-2 px-3 my-4"
          onClick={() => navigate("/agenda-cirular")}
        >
          {" "}
          Agenda Circulars
        </button>
        }
      </div>
    </div>
  );
};

const Profile = () => {
  return (
    <div class="userProfile ">
       <Helmet>
            <meta charSet="utf-8" />
            <title>User Profile</title>
            <meta name="description" content="User Profile" />
        </Helmet>
      <div style={{ maxWidth: "800px", margin: "auto" }}>
        <UserTable />
      </div>
    </div>
  );
};

export default Profile;
