import React, { useEffect, useState } from "react";
import {
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import "./style/footer.css";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "react-scroll-to-top";
import ATM from "./svg/ATM";
import Employee from "./svg/Employee";
import Deals from "./svg/Deals";
import Bank from "./svg/Bank";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Footer = ({ lang }) => {
  const [counterOn, setCounterOn] = useState(false);
  const [footerData, setFooterData] = useState({});

  const getFooterData = () => {
    axios
      .post("https://tgbdemo.techelecon.net/api/footer.php", { lang: lang })
      .then((response) => {
        setFooterData(response.data.responseResult);
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    AOS.init({ duration: 1500 });
    getFooterData();
  }, [lang]);

  const nevigate = useNavigate();
  const Tools = footerData?.Tools;
  const Information = footerData?.Information;
  const Menu = footerData?.Menu;
  const SocialMedia = footerData?.SocialMedia;
  const details = footerData?.Detail;

  return (
    <div class="footer">
      <ScrollToTop smooth top="20" />
      <div class="notificationSectionMain">
        <br />
        <div class="footer0" data-aos="zoom-in-up">
          <div class="footer0_1">
            {Tools?.map((el) => {
              return (
                <div
                  onClick={() => nevigate(`/${el.url}`)}
                  style={{ cursor: "pointer" }}
                >
                  <div>
                    <img class="mx-3" src={el.image} alt="" />
                  </div>
                  <div>
                    <p class="fw-bold fs-5 m-0"> {el.toolName}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <br />
      </div>

      <div class="footer1">
        {Menu?.map((el) => {
          return (
            <div>
              <ul class="footerUl">
                <li class="p-0">
                  <p class="fw-bold" style={{ color: "rgb(230, 70, 24)" }}>
                    {el.name}
                  </p>
                </li>
                <hr />
                {el.submenu.map((e) => {
                  return (
                    <a
                      class="text-dark "
                      style={{ fontSize: "14px", padding: "2px 0" }}
                      href={`/${e.url}`}
                    >
                      {e.name}
                    </a>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>

      <div class="footer2">
        <div>
          <p class="fw-bold m-0" style={{ color: "rgb(230, 70, 24)" }}>
            {details?.title}:
          </p>
        </div>
        <div>
          <p
            class="m-0 "
            dangerouslySetInnerHTML={{ __html: details?.line }}
          ></p>
        </div>
      </div>

      <div
        class="footer4"
        style={{ backgroundImage: `url(${details?.footer_bg})` }}
      >
        <div style={{ maxWidth: "90%", margin: "auto" }}>
          <br />
          <br />
          <div
            class="d-flex justify-content-around align-content-center"
            style={{ maxWidth: "300px", margin: "auto" }}
          >
            {SocialMedia?.map((el) => {
              return (
                <>
                  <a class="text-dark" href={`/${el.url}`} target="_blank">
                    <img src={el.icon} alt="" style={{ width: "50px" }} />
                  </a>
                </>
              );
            })}
          </div>
        </div>

        <div class="row align-items-center justify-content-around m-5">
          {Information?.map((el) => {
            return (
              <div class="col-12 col-md-6 col-lg-3">
                <img style={{ width: "55px" }} src={el.icon} alt="" />
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(true)}
                >
                  <p class="text-white m-0">
                    <span class="fs-3">
                      {counterOn && (
                        <CountUp
                          start={0}
                          end={el.number}
                          duration={2}
                          delay={0}
                        />
                      )}
                    </span>
                    <span class="fs-3">+</span>{" "}
                  </p>
                </ScrollTrigger>
                <p class="text-white fs-4">{el.title}</p>
              </div>
            );
          })}
        </div>
        <div class="p-2" style={{ background: "rgba(0,0,0,.5" }}>
          <p
            class="text-white text-start  mx-5"
            style={{ fontSize: "12px" }}
            dangerouslySetInnerHTML={{ __html: details?.copyright_desc }}
          ></p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
