import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const SearchResults = ({ lang, searchName }) => {
  const [searchData, setSearchData] = useState([]);
  console.log("searchData :", searchData);

  const searchContent = () => {
    axios
      .post("https://tgbdemo.techelecon.net/api/custom-search.php", {
        lang: lang,
        search: searchName,
      })
      .then((response) => {
        setSearchData(response.data.responseResult);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    searchContent();
  }, [searchName, lang]);
  return (
    <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Search Results</title>
            <meta name="description" content="Search Results" />
        </Helmet>
      <div class="text-start" style={{ marginTop: "10%" }}>
        <p class="mt-5 p-2 fs-2 fw-semibold">Search Results</p>
        <div
          class="row  justify-content-around gap-3 "
          style={{ width: "90%", margin: "auto" }}
        >
          {searchData?.map((el) => {
            return (
              <a
                class="col-md-3  col-sm-5 col-xs-11  border p-3 rounded-3 "
                style={{ cursor: "pointer" }}
                href={`${el.url}`}
              >
                <p class="fs-5 fw-semibold text-dark">{el.title}</p>
                <a class="text-success" href={`${el.url}`}>
                  Click Link{" "}
                </a>
              </a>
            );
          })}
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default SearchResults;
