import React from "react";
import { useEffect } from "react";
import { AiOutlineRight } from "react-icons/ai";
import "./style/SideMenuPages.css";
import AOS from "aos";
import "aos/dist/aos.css";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Pagination } from "react-bootstrap";
import { Helmet } from "react-helmet";

const AtmLocater = ({ lang }) => {
  const navigate = useNavigate();
  const [table, setTable] = useState();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const atmLocateData = () => {
    axios
      .post(" https://tgbdemo.techelecon.net/api/atm_detail.php", {
        lang: lang,
      })
      .then((response) => {
        setTable(response.data.responseResult);
      })
      .catch((error) => console.log("error", error));
  };
  const tBodyData = table?.tBody;
  const tHeadData = table?.tHead;

  const tBodyyy = tBodyData?.filter((el) => {
    if (search == "") {
      return el;
    } else if (el.office.toLowerCase().includes(search.toLowerCase())) {
      return el;
    } else if (el.region.toLowerCase().includes(search.toLowerCase())) {
      return el;
    } else if (el.district.toLowerCase().includes(search.toLowerCase())) {
      return el;
    } else if (el.branch.toLowerCase().includes(search.toLowerCase())) {
      return el;
    }
  });

  const totalPages = Math.ceil(tBodyyy?.length / 10);
  const indexOfLastItem = currentPage * 10;
  const indexOfFirstItem = indexOfLastItem - 10;
  const currentItems = tBodyyy?.slice(indexOfFirstItem, indexOfLastItem);
  //
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const tBody = currentItems?.map((el, i) => {
    return (
      <tr>
        <td>
          <p class=" m-0">{indexOfFirstItem + i + 1}</p>
        </td>
        <td>
          <p class="m-0">{el.office}</p>
        </td>
        <td>
          <p class="m-0">{el.region}</p>
        </td>
        <td>
          <p class="m-0">{el.district}</p>
        </td>
        <td>
          <p class="m-0">{el.branch}</p>
        </td>
        <td>
          <button class="btn btn-success" style={{ height: "40px" }}>
            <a href={`/${el.location}`} target="_blank">
              {" "}
              <p class="text-nowrap">Get Direction</p>
            </a>
          </button>
        </td>
      </tr>
    );
  });

  useEffect(() => {
    atmLocateData();
    AOS.init({ duration: 1500 });
  }, [lang]);
  return (
    <div class="slidePage">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Atm locator</title>
            <meta name="description" content="Atm locator" />
        </Helmet>
      <div class="p-3 text-start" style={{ background: "#f3f3f3" }}>
        <p class="m-0">
          {" "}
          <span>Atm locator</span>
        </p>
        <p class="fs-2 fw-semibold">Atm locator</p>
      </div>
      <div clas="text-start" style={{ padding: "25px" }}>
        <div class="text-start">
          <input
            type="text"
            class="form-control"
            placeholder="Search here "
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div
          class="mt-4"
          style={{ overflowX: "auto", height: "100%", overflowY: "hidden" }}
        >
          <Table striped bordered hover>
            <thead>
              <tr>
                <th
                  style={{ background: "#BB355A" }}
                  class="text-center text-white"
                >
                  <p class=" m-0">No.</p>
                </th>
                {tHeadData?.map((el) => {
                  return (
                    <th
                      style={{ background: "#BB355A" }}
                      class="text-center text-white"
                    >
                      <p class=" m-0 text-nowrap">{el.headName}</p>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>{tBody}</tbody>
          </Table>
          <div>
            <Pagination>
              {Array.from({ length: totalPages }, (_, i) => (
                <Pagination.Item
                  key={i}
                  active={i + 1 === currentPage}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default AtmLocater;
