import axios from "axios";
import jsPDF from "jspdf";
import React, { useState } from "react";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { AiOutlineDown } from "react-icons/ai";
import { BiChevronRight } from "react-icons/bi";
import { Navigate, useNavigate } from "react-router-dom";
import { RightSlider } from "./Home";
import "./style/downloadForm.css";
import Style from "./style/Style.module.css";
import { Helmet } from "react-helmet";

const ETender = (lang) => {
  const [formData, setFormData] = useState({});

  const downloadFormData = () => {
    axios
      .post("https://tgbdemo.techelecon.net/api/e_tender.php", lang)
      .then((response) => {
        setFormData(response.data);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    downloadFormData();
  }, [lang]);

  const data = formData?.ETenderForms;

  const showData = () => {
    let arr = [];
    for (let i = 0; i < data?.length; i++) {
      arr.push(
        <>
          <div
            class="downloadForm2_1"
            data-bs-toggle="collapse"
            href={`#${data[i].title.split(" ").join("").split("/").join("")}`}
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <p class="m-0 fw-bold" style={{ transform: "rotate(270deg)" }}>
              <span>
                <AiOutlineDown />
              </span>
            </p>
            <p class="m-0 fw-bold">{data[i].title}</p>
          </div>
          <div
            class="collapse"
            id={data[i].title.split(" ").join("").split("/").join("")}
            style={{ maxWidth: "1000px", margin: "0", transition: "1000ms" }}
          >
            <div class="card card-body">
              {data[i].forms.map((el) => {
                return (
                  <div>
                    <div>
                      <p class="p-3 m-0" style={{ fontWeight: "500" }}>
                        {el.title}
                      </p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p
                        class="text-success p-3 m-0"
                        style={{ cursor: "pointer", fontWeight: "500" }}
                      >
                        <a
                          class="text-success"
                          href={`${el.url}`}
                          target="_blanck"
                          download
                        >
                          Download PDF
                        </a>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      );
    }
    return arr;
  };

  return (
    <div class="downloadForm">
        <Helmet>
            <meta charSet="utf-8" />
            <title>E-Tender</title>
            <meta name="description" content="E-Tender" />
        </Helmet>
      <div class="downloadForm1 text-start">
        <p
          class="px-3 py-3 m-0"
          style={{ fontSize: "14px", fontWeight: "500" }}
        >
          {" "}
          <span>E-Tender</span>
        </p>
        <p class="px-3 fs-2 fw-semibold">E-Tender</p>
      </div>

      <div class="downloadForm2 p-4">{showData()}</div>
    </div>
  );
};

export default ETender;
