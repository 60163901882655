import React, { useEffect, useState } from "react";
import "./style/home.css";
import {
  AiFillCaretLeft,
  AiFillCaretRight,
  AiOutlineLeft,
  AiOutlineRight,
} from "react-icons/ai";
import ReactPlayer from "react-player";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import { BsCheckLg } from "react-icons/bs";
import { MdOutlineChevronRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Helmet } from "react-helmet";

export const RightSlider = () => {
  const nevigate = useNavigate();
  return (
    <div class="rightSlid">
      <button
        class="btn"
        style={{
          position: "absolute",
          left: "-20px",
          top: "47%",
          background: "#BB355A",
          width: "20px",
          height: "70px",
        }}
      >
        <AiFillCaretLeft class="text-white" style={{ marginLeft: "-10px" }} />
      </button>
      <div
        style={{ borderRadius: "15px 0 0 0" }}
        onClick={() => nevigate("/tgb/6/child_menu/rupay-debit-card")}
      >
        <img src={require("../images/home/atmCard.png")} alt="" />
        <p class="text-white mt-2 m-0">Rupay Atm Debit Card</p>
      </div>
      <div onClick={() => nevigate("/branch-locator")}>
        <img src={require("../images/home/location.png")} alt="" />
        <p class="text-white mt-2 m-0">Branch Locator</p>
      </div>

      <div onClick={() => nevigate("/atm-locator")}>
        <img src={require("../images/home/atm.png")} alt="" />
        <p class="text-white mt-2 m-0">ATM Locator</p>
      </div>

      <div onClick={() => nevigate("/emi-calculator")}>
        <img src={require("../images/home/emiCal.png")} alt="" />
        <p class="text-white mt-2 m-0">EMI Calculator</p>
      </div>
      <div
        style={{ borderRadius: "0 0 0 15px" }}
        onClick={() => nevigate("/apply-now")}
      >
        <img src={require("../images/home/applyNow.png")} alt="" />
        <p class="text-white mt-2 m-0">Apply Now</p>
      </div>
    </div>
  );
};

const Home = ({ lang }) => {
  const [counterOn, setCounterOn] = useState(false);

  const [home, setHome] = useState({});

  const navigate = useNavigate();

  const getHomeData = () => {
    axios
      .post("https://tgbdemo.techelecon.net/api/home.php", { lang: lang })
      .then((response) => {
        setHome(response.data.responseResult);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getHomeData();
  }, [lang]);

  useEffect(() => {
    AOS.init({ duration: 1500 });

    var myIndex = 0;
    carousel();

    function carousel() {
      var i;
      var x = document.getElementsByClassName("mySlides");
      for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
      }
      myIndex++;
      if (myIndex > x.length) {
        myIndex = 1;
      }
      x[myIndex - 1].style.display = "block";
      setTimeout(carousel, 4000);
    }
  }, []);

  const Slider = home?.Slider;
  const LargSlider = Slider?.large_image;
  const SmallSlider = Slider?.small_image;
  const sliderUrl = Slider?.url;
  const WhatsNew = home?.WhatsNew;
  const NewsLins = home?.WhatsNew?.newsLins;

  const MobileBankingServices = home?.MobileBankingServices;
  const QuickLinks = home?.QuickLinks;
  const DownloadMobile = home?.DownloadMobile;
  const DownloadImage1 = home.DownloadMobile?.images[0];
  const DownloadImage2 = home.DownloadMobile?.images[1];
  const DownloadImage3 = home.DownloadMobile?.images[2];
  const DownloadImage4 = home.DownloadMobile?.images[3];
  const DownloadImage5 = home.DownloadMobile?.images[4];
  const DownloadImage6 = home.DownloadMobile?.images[5];

  const OurOfferings = home?.OurOfferings;
  const KnowMoreAboutUs = home?.KnowMoreAboutUs;
  const InterestRates = home?.InterestRates;
  const ExclusiveOffers = home?.ExclusiveOffers;
  const ExclusiveOffersLine = ExclusiveOffers?.exclusiveOffers;
  const ExclusiveOffersSlider = ExclusiveOffers?.slider;
  const Notification = home?.Notification;
  const EXoffer1 = [];
  const EXoffer2 = [];
  for (let i = 0; i < ExclusiveOffersLine?.length; i++) {
    if (i < ExclusiveOffersLine?.length / 2) {
      EXoffer1.push(ExclusiveOffersLine[i]);
    } else {
      EXoffer2.push(ExclusiveOffersLine[i]);
    }
  }

  return (
    <div class="containe-xxl home">
       <Helmet>
            <meta charSet="utf-8" />
            <title>Tripura Gramin Bank | Personal Banking | Netbanking Services</title>
            <meta name="description" content="Discover unparalleled banking services at Tripura Gramin Bank, where our vision is to be a customer-centric, tech-savvy institution rooted in rural essence. With the largest branch network in Tripura, we are committed to providing prompt and inclusive financial services to the rural masses. Our focus on priority sector short-term credit, including KCC, GCC, JLG, and MSME, empowers the agricultural sector and uplifts the denied and deprived rural communities. Experience banking with a purpose, driving overall business and profitability while fostering rural development." />
        </Helmet>
      <div class="home1">
        <div id="carouselExampleIndicators">
          <Carousel
            infiniteLoop={true}
            swipeable={false}
            autoPlay={true}
            showThumbs={false}
            stopOnHover={false}
          >
            {LargSlider?.map((el) => {
              return (
                <a href={`${el.url}`} target="_blanck">
                  <div style={{ width: "100%", height: "55vh" }}>
                    <img
                      style={{ width: "100%", height: "55vh" }}
                      src={el.img}
                      alt=""
                    />
                  </div>
                </a>
              );
            })}
          </Carousel>
        </div>
        <div id="carouselExampleIndicatorsSmall">
          <Carousel
            infiniteLoop={true}
            swipeable={false}
            autoPlay={true}
            showThumbs={false}
          >
            {SmallSlider?.map((el) => {
              return (
                <a href={`${el.url}`} target="_blanck">
                  <div style={{ width: "100%", height: "75vh" }}>
                    <img
                      style={{ width: "100%", height: "75vh" }}
                      src={el.img}
                      alt=""
                    />
                  </div>
                </a>
              );
            })}
          </Carousel>
        </div>
      </div>
      <div class="home2">
        <div class="whatsNew">
          <p class="fs-4 fw-bold text-white my-0">{WhatsNew?.title}</p>
        </div>
        <div class="whatsNew_lins">
          <marquee
            behavior="scroll"
            direction="left"
            style={{ fontSize: "18px" }}
          >
            {NewsLins?.map((el) => {
              return (
                <>
                  <a class="text-dark" href={`/${el.url}`} target="_blank">
                    <span
                      class="marquee_new text-danger fw-bold"
                      style={{ marginLeft: "80px", marginRight: "10px" }}
                    >
                      {el.title}!
                    </span>
                    <span style={{ cursor: "pointer" }}>{el?.newslink}</span>
                  </a>
                </>
              );
            })}
          </marquee>
        </div>
      </div>
      <div class=" home3">
        <div class="home3_0 ">
          <div class="home3_1 ">
            <p class="mt-5">{MobileBankingServices?.title}</p>
            <div class="home3_1_1 mt-5">
              {MobileBankingServices?.mobileBankingServices?.map((el) => {
                return (
                  <div onClick={() => navigate(`/${el.url}`)}>
                    <div
                      class="svg"
                      dangerouslySetInnerHTML={{ __html: el?.svg }}
                    ></div>
                    <div>
                      <p class="mt-2">{el.title}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div class="home3_2">
            <div class="home3_2_1">
              <p class="mt-3 mb-4 mx-3">{QuickLinks?.title}</p>
              {QuickLinks?.buttonName.map((el) => {
                return (
                  <button
                    class="d-flex justify-content-between  align-items-center "
                    onClick={() => navigate(`/${el.url}`)}
                  >
                    {" "}
                    <p class="mb-0">{el.name}</p>
                    <span>
                      <AiOutlineRight />
                    </span>
                  </button>
                );
              })}
            </div>
            <div
              class="home3_2_2 pb-0"
              style={{ borderRadius: "15px 15px 0px 0px" }}
            >
              <p class="home3_2_2p1 mx-2 mb-2 ">{DownloadMobile?.title}</p>
              <div class="mb-2">
                <img class="mySlides" src={DownloadImage1} alt="" />
                <img class="mySlides" src={DownloadImage2} alt="" />
                <img class="mySlides" src={DownloadImage3} alt="" />
                <img class="mySlides" src={DownloadImage4} alt="" />
                <img class="mySlides" src={DownloadImage5} alt="" />
                <img class="mySlides" src={DownloadImage6} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="home5">
        <div>
          <p class=" home5p1 mt-5 " data-aos="fade-up">
            {OurOfferings?.title}
          </p>
          <div class="ourOfferings">
            {OurOfferings?.ourOffering?.map((el) => {
              return (
                <div
                  class="offer"
                  data-aos="fade-up"
                  onClick={() => navigate(`/${el.url}`)}
                >
                  <div class="offer1">
                    <img src={el.img} alt="" />
                  </div>
                  <div class="offer2">
                    <div
                      style={{
                        display: "grid",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <img
                        style={{ position: "absolute", top: "0" }}
                        data-aos="fade-right"
                        src={el.img2}
                        alt=""
                      />
                    </div>
                    <div style={{ display: "grid", alignItems: "center" }}>
                      <p class="fs-5 fw-semibold mt-2 mb-0">{el.title}</p>
                      <p
                        class="m-0"
                        style={{
                          width: "100%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <span style={{ color: "rgb(0, 146, 83" }}>
                          <AiFillCaretRight />{" "}
                        </span>{" "}
                        <span class="mx-1 ">{el.text1}</span>
                      </p>
                      <p class="m-0">
                        <span style={{ color: "rgb(0, 146, 83" }}>
                          <AiFillCaretRight />{" "}
                        </span>{" "}
                        <span class="mx-1">{el.text2}</span>
                      </p>
                    </div>
                    <div style={{ position: "relative" }}>
                      <button
                        style={{ position: "absolute", bottom: "0" }}
                        class="OurOfferingsBtn"
                        onClick={() => navigate(`/${el.url}`)}
                      >
                        Know More
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <br />
        </div>
      </div>

      <div class="home4">
        <div class="home4_1">
          <p class=" home4_1p1 mt-3 mb-2" data-aos="fade-up">
            {KnowMoreAboutUs?.title}
          </p>
          <p
            class="home4_1p2 mb-0"
            data-aos="fade-up"
            style={{ textAlign: "justify" }}
            dangerouslySetInnerHTML={{ __html: KnowMoreAboutUs?.lines }}
          ></p>

          <div class="home4_2 mt-5">
            {KnowMoreAboutUs?.knowMore.map((el) => {
              return (
                <div class="reactPlayer" data-aos="fade-up">
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    controls="false"
                    url={el}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div class="home6">
        <div class="interestRate">
          <p class="interestRateP " data-aos="fade-up">
            {InterestRates?.title}
          </p>
          <div class="interestRateDetails" data-aos="fade-up">
            {InterestRates?.interestRateDetails.map((el) => {
              return (
                <div data-aos="fade-up">
                  <img src={el.img} alt="" />
                  <ScrollTrigger
                    onEnter={() => setCounterOn(true)}
                    onExit={() => setCounterOn(false)}
                  >
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      class="m-0"
                    >
                      <span class="fs-4 fw-bold">
                        {counterOn && (
                          <CountUp
                            start={0}
                            end={el.rates}
                            decimals={2}
                            duration={2}
                            delay={0}
                          />
                        )}
                      </span>
                      <span class="fs-4 fw-bold">%</span>{" "}
                      <span class="text-secondary fs-5">P.A.*</span>{" "}
                    </p>
                  </ScrollTrigger>
                  <p class="fs-5 fw-semibold m-0">{el.loan_types}</p>
                </div>
              );
            })}
          </div>
          <br />
        </div>
      </div>

      <div class="home7">
        <div class="exclusive my-5">
          <h1 class="display-4 fw-semibold mb-4" data-aos="fade-up">
            {ExclusiveOffers?.title}
          </h1>
          <div class="exclusiveOffers" data-aos="fade-up">
            <div class="exclusiveOffers1">
              <Carousel
                infiniteLoop={true}
                swipeable={false}
                autoPlay={true}
                showThumbs={false}
                stopOnHover={false}
                style={{ height: "300px" }}
              >
                {ExclusiveOffersSlider?.map((el) => {
                  return (
                    <div style={{ width: "100%", height: "300px" }}>
                      <img
                        style={{ width: "100%", height: "300px" }}
                        src={el}
                        alt=""
                      />
                    </div>
                  );
                })}
              </Carousel>
            </div>
            <div class="exclusiveOffers2">
              <div>
                <Carousel
                  infiniteLoop={true}
                  swipeable={false}
                  autoPlay={true}
                  showThumbs={false}
                  stopOnHover={false}
                  style={{ height: "300px" }}
                >
                  {EXoffer1?.map((el) => {
                    return (
                      <div
                        class="p-3 text-start exOffers"
                        style={{ backgroundColor: `${el.backgroundColor}` }}
                      >
                        <div style={{ height: "60px" }}>
                          <img
                            style={{ width: "unset", height: "100%" }}
                            src={el.img}
                            alt=""
                          />
                        </div>
                        <div
                          class="row mt-2 justify-content-cente"
                          style={{ height: "100%" }}
                        >
                          <div class="d-grid ">
                            <p class="fs-3 fw-semibold m-0">{el.offer}</p>
                            <p
                              class="fs-5 fw-bold m-0"
                              style={{ color: "#EE7601" }}
                            >
                              {el.know}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
              <div>
                <Carousel
                  infiniteLoop={true}
                  swipeable={false}
                  autoPlay={true}
                  showThumbs={false}
                  stopOnHover={false}
                  style={{ height: "300px" }}
                >
                  {EXoffer2?.map((el) => {
                    return (
                      <div
                        class="p-3 text-start exOffers"
                        style={{ backgroundColor: `${el.backgroundColor}` }}
                      >
                        <div style={{ height: "60px" }}>
                          <img
                            style={{ width: "unset", height: "100%" }}
                            src={el.img}
                            alt=""
                          />
                        </div>
                        <div
                          class="mt-2 row justify-content-center"
                          style={{ height: "100%" }}
                        >
                          <div class="d-grid ">
                            <p class="fs-3 fw-semibold m-0">{el.offer}</p>
                            <p
                              class="fs-5 fw-bold"
                              style={{ color: "#EE7601" }}
                            >
                              {el.know}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="home8">
        <div class="notification">
          <br />
          <br />
          <div class="notification1p">
            <p class="notification_p1" data-aos="fade-right">
              {Notification?.title}{" "}
              <span class="fs-2" style={{ color: "rgb(187, 53, 90)" }}>
                <AiFillCaretRight />
                <AiFillCaretRight />
                <AiFillCaretRight />
              </span>
            </p>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "rgb(238, 118, 1)",
                cursor: "pointer",
              }}
              onClick={() => navigate("/tgb/59/child_menu/notification")}
            >
              More{" "}
              <span>
                <MdOutlineChevronRight class="fw-bold" />
              </span>
            </p>
          </div>
          <div class="notificationShow">
            {Notification?.notification.map((el) => {
              return (
                <div class="notificationDiv" data-aos="fade-up">
                  <p class="fw-semibold  p-0">
                    <a class="text-dark" href={`/${el.url}`} target="_blank">
                      <span>
                        <BsCheckLg style={{ color: "green" }} />
                      </span>
                      <span class="px-3">{el.notification}</span>
                    </a>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
