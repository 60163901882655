import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style/login.css";
import swal from "sweetalert";
import md5 from "md5";
import { BiHide, BiShow } from "react-icons/bi";
import { Helmet } from "react-helmet";
 
 


const ForgetPass = () => {
  const [state, setState] = useState({});
  const [userFormetData, setUserFormetData] = useState({}); 
  const [show2, setShow2] = useState(false); 
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileOtp, setMobileOtp] = useState("");
  const [criptOtp, setCriptOtp] = useState();

  const [user, setUser] = useState("");
  const [userCun, setUserCun] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [criptPassword, setScriptPassword] = useState();

  const [details,setDetails] = useState({})

  const [isValid, setIsValid] = useState(false);


  const navigate = useNavigate();

  const isValidPhoneNumber = (phoneNumber) => {
    const regex = /^[6-9]\d{9}$/; // Indian mobile numbers start with 6, 7, 8, or 9 and are 10 digits long
    return regex.test(phoneNumber);
  };

  useEffect(()=>{
    setIsValid(isValidPhoneNumber(mobileNumber))
  },[mobileNumber]) 
 

  const forgetOtpUser = () => {
    axios
      .post("https://tgbdemo.techelecon.net/api/forgot_password.php", {
        Phone: `${mobileNumber}`,
      })
      .then((res) => {
        // console.log(res.data);
        if(res.data.responseStatus){ 
          setState(res.data);
          swal(res.data.responseMessage);
          setShow2(true);
        }else{
          swal(res.data.responseMessage);

        }
      })
      .catch((err) => {
        swal('You need to provide valid credentials'); 
      });
  };

  const veryfyNumber =()=>{
    if(isValid){ 
      forgetOtpUser()
    }else{
      swal('You need to provide valid Mobile Number'); 
    }
  }

  useEffect(() => {
    setCriptOtp(md5(mobileOtp));
  }, [mobileOtp]); 


  useEffect(() => {
    setScriptPassword(md5(user));
  }, [user]);

 
  useEffect(() => {
    const forgatData = { ...details };
    forgatData.Phone = mobileNumber;
    forgatData.Password = criptPassword;
    forgatData.OTP = criptOtp; 
    setDetails(forgatData);
  }, [criptPassword, mobileNumber, criptOtp]);

 
  const setUserPassword = () => {
    axios
      .post(
        "https://tgbdemo.techelecon.net/api/update_password.php",
        details
      )
      .then((res) => {
        if (res.status === 200) {
          setState(res.data);
          swal(res.data.responseMessage);
          navigate("/log-in");
        }
      })
      .catch((err) => {
        swal('You need to provide valid credentials'); 
      });
  };

  const setPassword = () => {
    if (user == userCun) {
      setUserPassword();
    } else {
      swal("Please fill same password");
    }
  };

  return (
    <div class="login">
       <Helmet>
            <meta charSet="utf-8" />
            <title>Forget Password</title>
            <meta name="description" content="Forget Password" />
        </Helmet>
      <div class="login1 text-start p-3">
        <p class="fs-1 fw-bold">Forget Password</p>
        <div class=''> 
          <input
            class="form-control p-2 "
            type="text"
            placeholder="Phone Number"
            name="Phone"
            maxlength="10"
            onChange={(e) => setMobileNumber(e.target.value)}
          />  
        <div> 
        </div>
        </div>
        {show2 && (
          <div>

                <input
                  class="form-control p-2 mt-4 "
                  type="text"
                  placeholder="OTP"
                  name="OTP"
                  onChange={(e) => setMobileOtp(e.target.value)}
                />
                <br />
      
      <div class="input-group">
          <input
            class="form-control p-2"
            type={showPassword ? "text" : "password"}
            placeholder="New Password"
            value={user}
            onChange={(e) => setUser(e.target.value)}
          />
          {showPassword ? (
            <span
              class="input-group-text"
              onClick={() => setShowPassword(false)}
            >
              <BiHide />
            </span>
          ) : (
            <span
              class="input-group-text"
              onClick={() => setShowPassword(true)}
            >
              <BiShow />
            </span>
          )}
        </div>

        <br />
        <div class="input-group">
          <input
            class="form-control p-2"
            type={showPassword2 ? "text" : "password"}
            placeholder="Confirm Password"
            value={userCun}
            onChange={(e) => setUserCun(e.target.value)}
          />
          {showPassword2 ? (
            <span
              class="input-group-text"
              onClick={() => setShowPassword2(false)}
            >
              <BiHide />
            </span>
          ) : (
            <span
              class="input-group-text"
              onClick={() => setShowPassword2(true)}
            >
              <BiShow />
            </span>
          )}
        </div>
          </div>
        )}
        <br />
        {show2 ? (
          <div>
            <button
              class="btn btn-success p-2 px-3"
              id="success"
              onClick={setPassword}
            >
              {" "}
              Submit
            </button>
            <button
              class="btn btn-success p-2 px-3 mx-3"
              id="success"
              onClick={forgetOtpUser}
            >
              {" "}
              Resend OTP
            </button>
          </div>
        ) : (
          <button
            class="btn btn-success p-2 px-3"
            id="success"
            onClick={veryfyNumber}
          >
            {" "}
            Verify
          </button>
        )}
      </div>
    </div>
  );
};

export default ForgetPass;
