import axios from "axios";
import md5 from "md5";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import swal from "sweetalert";
import Swal from "sweetalert2";
const ApplyNow2 = () => {
  const [query, setQuery] = useState();
  const [otpBtn, setOtpBtn] = useState(false);
  const [details, setDetails] = useState({});
  const [subBtn, setSubBtn] = useState(true);
  const [otp, setOtp] = useState("");
  const [fillOtp, setFillOtp] = useState("");
  const [criptOtp, setCriptOtp] = useState();

  const userApplyForm = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  useEffect(() => {
    setCriptOtp(md5(fillOtp));
  }, [fillOtp]);

  useEffect(() => {
    const fillOtp = { ...details };
    fillOtp.OTP = criptOtp;
    setDetails(fillOtp);
  }, [criptOtp]);

  const queryFill = (e) => {
    const query = { ...details };
    query.PageName = e.target.value;
    setDetails(query);
  };

  useEffect(() => {
    if (
      details.Name &&
      details.Name != "" &&
      details.Email &&
      details.Email != "" &&
      details.Phone &&
      details.Phone != "" &&
      details.TGB_Branch &&
      details.TGB_Branch != "" &&
      details.Comments &&
      details.Comments != "" &&
      details.PageName &&
      details.PageName != "" &&
      fillOtp
    ) {
      setSubBtn(false);
    } else {
      setSubBtn(true);
    }
  }, [details, fillOtp]);

  const sendOtp = () => {
    axios
      .post(`https://tgbdemo.techelecon.net/api/otp_apply_now_forms.php`, {
        Phone: details.Phone,
      })
      .then((response) => {
        if (response.data.responseStatus == true) {
          setOtpBtn(true);
          swal(response.data.responseMessage);
        } else {
          swal(response.data.responseMessage);
          setOtpBtn(false);
        }
      })
      .catch((error) => console.log(error));
  };

  const submitDetails = () => {
    axios
      .post(
        `https://tgbdemo.techelecon.net/api/apply_now_forms.php`,
        details
      )
      .then((response) => {
        if (response.data.responseStatus == true) {
          Swal.fire({
            icon: "success",
            title: `Reference no. - ${response.data.responseRefrenceNo} `,
            html:
              "Great! Your query detail has been successfully added and a reference number has been generated for your convenience. Your reference number is</b>, " +
              `<b>${response.data.responseRefrenceNo}</b>. ` +
              "Our team will now be able to track and address your query promptly. Thank you for choosing our services!",
          });
          setOtpBtn(false);

          setDetails({
            Name: " ",
            Email: " ",
            Phone: " ",
            PageName: " ",
            TGB_Branch: " ",
            Comments: " ",
          });
          setFillOtp("");
        } else {
          swal(response.data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      class="text-start px-3"
      style={{ maxWidth: "600px", margin: "15% auto" }}
    >
       <Helmet>
            <meta charSet="utf-8" />
            <title>Ask your question or Apply Now Now</title>
            <meta name="description" content="Apply Now" />
        </Helmet>
      <h3 class="fs-2  fs-bold text-center">Ask your question or Apply Now</h3>
      <div class="form-group">
        <label>Name</label>
        <input
          type="text"
          class="form-control"
          placeholder="Name"
          name="Name"
          value={details.Name}
          onChange={userApplyForm}
        />
      </div>

      <div class="form-group mt-2">
        <label>Email</label>
        <input
          type="email"
          class="form-control"
          placeholder="tripura@gmail.com"
          value={details.Email}
          name="Email"
          onChange={userApplyForm}
        />
      </div>

      <label class="mt-2"> Phone no.</label>
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="Phone Number"
          maxLength={10}
          name="Phone"
          value={details.Phone}
          onChange={userApplyForm}
        />
        <span>
          <button
            class="btn btn-success"
            style={{ zIndex: "0" }}
            disabled={otpBtn ? true : false}
            onClick={() => sendOtp()}
          >
            Verify
          </button>
        </span>
      </div>

      {!otpBtn ? (
        ""
      ) : (
        <div class="form-group mt-2">
          <input
            type="text"
            class="form-control"
            placeholder="OTP"
            name="PageName"
            value={fillOtp}
            maxLength={6}
            onChange={(e) => setFillOtp(e.target.value)}
          />
        </div>
      )}

      <div class="form-group mt-2">
        <label>Nearest TGB Branch</label>
        <input
          type="email"
          class="form-control"
          placeholder="Nearest TGB Branch"
          value={details.TGB_Branch}
          name="TGB_Branch"
          onChange={userApplyForm}
        />
      </div>

      <div class="form-group mt-2">
        <label>Query</label>
        <select
          class="form-select"
          id="inputGroupSelect01"
          onChange={queryFill}
        >
          <option value=" "> </option>
          <option value="New Account">New Account</option>
          <option value="Loan">Loan</option>
          <option value="Other Services">Other Service</option>
        </select>
      </div>

      <div class="form-group mt-2">
        <label>Comments</label>
        <textarea
          class="form-control"
          placeholder="Leave a comment here"
          name="Comments"
          value={details.Description}
          onChange={userApplyForm}
        ></textarea>
      </div>

      <button
        class="btn btn-success form-control mt-3"
        disabled={subBtn ? true : false}
        onClick={submitDetails}
      >
        Submit
      </button>
    </div>
  );
};
export default ApplyNow2;
